function createObject() {
  let example = {
    id: "PfadeAutomatisierung",
    workOrder:
      "Betrachten Sie die Zahlentripel und wählen sie zwei Zusammengehörige in Anbetracht der vorgestellten Stützpunkte aus.",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Stützpunkt",
          question: "Wählen Sie zwei zusammengehörige Stützpunkte aus.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/01.png"),
              solutionImage: require("@/assets/nzr/Pfade/Automatisierung/02.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/03.png"),
              solutionImage: require("@/assets/nzr/Pfade/Automatisierung/04.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/05.png"),
              solutionImage: require("@/assets/nzr/Pfade/Automatisierung/06.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/07.png"),
              solutionImage: require("@/assets/nzr/Pfade/Automatisierung/08.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Automatisierungsgruppe",
          question:
            "Wählen Sie nun eine angemessene Automatisierungsgruppe ausgehend von den zwei gewählten Stützpunkten aus, die hilft, ein Verständnis für das Teil-Ganzes-Konzept aufzubauen und die Automatisierung weiterer Zahlentripel zu unterstützen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/09.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/10.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/11.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/nzr/Pfade/Automatisierung/12.png"),
            },
          ],
        },
      ],
    ],
  };

  const SP_1 =
    "Mit dem ersten Stützpunkt wird die „Kraft der Fünf“ angesprochen, welche in der Regel mit den Händen gezeigt werden und deswegen schnell automatisiert werden kann. Hierbei bildet die 5 einen der beiden Teile und ist als dieser vorgegeben. Der fehlende Teil wird gesucht. ";

  const SP_2 =
    "In Stützpunkt 2 wird auf die „Kraft der Fünf“ angespielt, indem die 5 als fehlender Teil identifiziert werden soll. Der zweite Teil, der benötigt wird, um auf das Ganze zu ergänzen, ist vorgegeben. ";

  const SP_3 =
    "Mit dem dritten Stützpunkt werden die Summanden gesucht, welche zusammen mit der 1 als zweiten Teil das Ganze ausmachen. Diese Struktur kann oftmals schnell automatisiert werden. ";

  const SP_4 =
    "In Stützpunkt 4 soll in allen drei Tripeln ein vorgegebener Teil um 1 zum Ganzen ergänzt werden. Da vorgegebener Teil und Ganzes nur um eins voneinander entfernt sind, gelingt das Finden der 1 meist sehr schnell. ";

  const AG_1 =
    "In der ersten Automatisierungsgruppe ist die 6 als Teil konstant, während das Ganze von Tripel zu Tripel unterschiedlich ist. ";

  const AG_2 =
    "In Automatisierungsgruppe 2 wird erneut die Struktur der „Kraft der Fünf“ angesprochen. Lediglich das Ganze unterscheidet sich im Vergleich zum Stützpunkt. ";

  const AG_3 =
    "Automatisierungsgruppe 3 zeichnet sich dadurch aus, dass in allen Tripeln 2 als fehlender Teil identifiziert werden muss. ";

  const AG_4 =
    "In Automatisierungsgruppe 4 ist wird keine mathematische Struktur durch alle drei Teilaufgaben gleichermaßen fokussiert. ";

  const PASSEND =
    "Beide Stützpunkte sprechen dieselbe mathematische Struktur und dieselben einprägsamen Aufgaben an. ";

  const AEHNLICH =
    "Beide Stützpunkte sprechen zwar eine ähnliche Struktur an, allerdings stehen diese nicht in einem unmittelbaren Zusammenhang, da sie unterschiedliche einprägsame Aufgaben ansprechen. ";

  const NICHT_PASSEND =
    "Die Stützpunkte fokussieren unterschiedliche mathematische Strukturen! ";

  const BEIDE =
    "Die Automatisierungsgruppe baut passend auf den Stützpunkten auf und unterstützt den Erkenntnisgewinn und die Automatisierung. ";

  const EINER =
    "Diese Automatisierungsgruppe baut zutreffend auf einem der gewählten Stützpunkte auf. Ausgehend von diesem wird die Automatisierung der Tripel unterstützt. Die Struktur des anderen gewählten Stützpunktes wird jedoch nicht fokussiert. ";

  const KEINER =
    "Diese Automatisierungsgruppe baut nicht auf den gewählten Stützpunkten auf und kann nicht aus dieser abgeleitet werden, weshalb die Automatisierung der Zahlentripel hier nicht unterstützt wird. ";

  example.responses = [
    {
      id: "12",
      text: PASSEND + BEIDE + SP_1 + SP_2,
    },
    {
      id: "112",
      text:
        PASSEND +
        BEIDE +
        SP_1 +
        SP_2 +
        AG_1 +
        "Somit können ausgehend von der Kraft der Fünf andere Zahlentripel abgeleitet werden.",
    },
    {
      id: "113",
      text:
        AEHNLICH +
        EINER +
        SP_1 +
        SP_3 +
        AG_1 +
        "Die Ableitung geht hier lediglich von der Kraft der Fünf aus. Eine Ableitung von der 1 als konstanten Teil ist nicht so unmittelbar zu erwarten.",
    },
    {
      id: "114",
      text:
        NICHT_PASSEND +
        EINER +
        SP_1 +
        SP_4 +
        AG_1 +
        "Die Ableitung geht hier lediglich von der Kraft der Fünf aus. Eine Ableitung der Automatisierungsgruppe von Stützpunkt 4 ist nicht so unmittelbar zu erwarten.",
    },
    {
      id: "123",
      text:
        NICHT_PASSEND +
        KEINER +
        SP_2 +
        SP_3 +
        AG_1 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten.",
    },
    {
      id: "124",
      text:
        AEHNLICH +
        KEINER +
        SP_2 +
        SP_4 +
        AG_1 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten.",
    },
    {
      id: "134",
      text:
        PASSEND +
        KEINER +
        SP_3 +
        SP_4 +
        AG_1 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten, da 6 nicht unmittelbar von der 1 aus den Stützpunkten abgeleitet werden kann.",
    },

    {
      id: "212",
      text:
        PASSEND +
        "Allerdings ist die Automatisierungsgruppe strukturell gleich wie der erste Stützpunkt. " +
        SP_1 +
        SP_2 +
        AG_2 +
        "Neue Erkenntnisse können nicht gewonnen werden, weshalb diese Gruppe nicht geeignet ist.",
    },
    {
      id: "213",
      text:
        AEHNLICH +
        "Allerdings ist die Automatisierungsgruppe strukturell gleich wie der erste Stützpunkt. " +
        SP_1 +
        SP_3 +
        AG_2 +
        "Neue Erkenntnisse können nicht gewonnen werden, weshalb diese Gruppe nicht geeignet ist.",
    },
    {
      id: "214",
      text:
        NICHT_PASSEND +
        SP_1 +
        SP_4 +
        AG_2 +
        "Neue Erkenntnisse können nicht gewonnen werden, da die Automatisierungsgruppe strukturgleich zum ersten Stützpunkt ist.",
    },
    {
      id: "223",
      text: NICHT_PASSEND + SP_2 + SP_3 + AG_2 + KEINER,
    },
    {
      id: "224",
      text: AEHNLICH + SP_2 + SP_4 + AG_2 + KEINER,
    },
    {
      id: "234",
      text: PASSEND + SP_3 + SP_4 + AG_2 + KEINER,
    },

    {
      id: "312",
      text:
        PASSEND +
        KEINER +
        SP_1 +
        SP_2 +
        AG_3 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten, da 2 nicht unmittelbar von der 5 aus den Stützpunkten abgeleitet werden kann.",
    },
    {
      id: "313",
      text:
        AEHNLICH +
        KEINER +
        SP_1 +
        SP_3 +
        AG_3 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten.",
    },
    {
      id: "314",
      text:
        NICHT_PASSEND +
        EINER +
        SP_1 +
        SP_4 +
        AG_3 +
        "Die Ableitung geht hier lediglich von Stützpunkt 4 aus. Eine Ableitung von dem ersten Stützpunkt, welcher die Kraft der Fünf thematisiert, ist nicht zu erwarten.",
    },
    {
      id: "323",
      text:
        NICHT_PASSEND +
        KEINER +
        SP_2 +
        SP_3 +
        AG_3 +
        "Eine Ableitung der Automatisierungsgruppe ausgehend von den Stützpunkten ist nicht zu erwarten.",
    },
    {
      id: "324",
      text:
        AEHNLICH +
        EINER +
        SP_2 +
        SP_4 +
        AG_3 +
        "Die Ableitung geht hier lediglich von Stützpunkt 4 aus. Eine Ableitung von dem zweiten Stützpunkt, welcher die Kraft der Fünf thematisiert, ist nicht zu erwarten.",
    },
    {
      id: "334",
      text:
        PASSEND +
        BEIDE +
        SP_3 +
        SP_4 +
        AG_3 +
        "Somit kann ausgehend von den Zahlentripeln mit 1 als einen der Teile weitere Tripel mit 2 als Teil abgeleitet werden.",
    },
    {
      id: "412",
      text: PASSEND + SP_1 + SP_2 + AG_4 + KEINER,
    },
    {
      id: "413",
      text: AEHNLICH + SP_1 + SP_3 + AG_4 + KEINER,
    },
    {
      id: "414",
      text: NICHT_PASSEND + SP_1 + SP_4 + AG_4 + KEINER,
    },
    {
      id: "423",
      text: NICHT_PASSEND + SP_2 + SP_3 + AG_4 + KEINER,
    },
    {
      id: "424",
      text: AEHNLICH + SP_2 + SP_4 + AG_4 + KEINER,
    },
    {
      id: "434",
      text: PASSEND + SP_3 + SP_4 + AG_4 + KEINER,
    },
  ];

  return example;
}

export default createObject();
