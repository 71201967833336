<template>
  <div>
    <h2 class="faledia-caption">Teil-Ganzes Verständnis</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>Eine Gesamtmenge in zwei oder mehr Teilmengen zerlegen</li>
          <li>Eine Gesamtmenge aus Teilmengen (wieder) zusammensetzen</li>
          <li>
            Verständnis dafür entwickeln, dass sich die Mächtigkeit einer
            Gesamtmenge nicht verändert, egal in welche und wie viele Teilmengen
            diese zerlegt wird.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Das Verhältnis von einer Menge und ihrer Teilmengen wird als
      Teil-Ganzes-Konzept (auch: Teil-Teil-Ganzes-Konzept) bezeichnet (Scherer &
      Moser Opitz, 2010). Die Erkenntnis, dass eine Gesamtmenge sich in zwei
      oder mehrere Teilmengen zerlegen oder sich daraus (wieder) zusammensetzen
      lässt, ohne dass sich die Mächtigkeit der Gesamtmenge ändert, ist bereits
      für den Anfangsunterricht zentral. Vorstellungen über Zahlen müssen nicht
      nur tragfähig und vielfältig – also kardinal (eine Zahl als Menge) und
      ordinal (eine Zahl als feste Position in einer Reihenfolge) – sondern auch
      mit Relationen von Zahlen zueinander entwickelt werden. Dazu sind vor
      allem die Teil-Ganzes-Beziehungen von Zahlen und ihren Zerlegungen
      bedeutsam (Häsel-Weide, 2016).
    </p>
    <p>
      So lässt sich die Zahl 8 als Menge mit Plättchen darstellen. Es wird
      dadurch deutlich, dass sie sich beispielsweise aus zwei Teilmengen der
      Mächtigkeiten 5 und 3 zusammensetzen lässt, ohne dass sich die Anzahl der
      Gesamtmenge (8) dabei ändert. Aus dieser Erkenntnis lassen sich
      Folgerungen ableiten:
    </p>
    <AppExpandableImage
      contain
      :src="require('@/assets/nzr/TeilGanzes.png')"
      parentId="nzr"
      elementId="nzr_teilganzes.png"
    >
    </AppExpandableImage>
    <br />
    <p>
      Im Verlauf des ersten Schuljahres sind die Kinder aufgefordert, Additions-
      und Subtraktionsaufgaben im Zwanzigerraum zu lösen (MSW NRW, 2021). Aus
      didaktischer Sicht sollte dies auf der Grundlage eines möglichst
      ausgebildeten Teil-Ganzes-Konzepts und unter Ausnutzung von Strukturen
      geschehen (Häsel-Weide, 2016). Eine wichtige Erkenntnis in diesem
      Zusammenhang ist, dass sich die Gesamtanzahl durch die Zerlegung in
      Teilmengen nicht verändert (ebd.). Auf dieser Grundlage kann ein
      Verständnis für weitere mathematische Muster wie die Konstanzgesetze erst
      möglich werden.
    </p>
    <p>
      Die Einsicht in Muster und Strukturen, wie sie das Teil-Ganzes-Konzept
      aufbauen kann, ermöglicht das flexible Rechnen und trägt somit zur
      Ablösung vom zählenden Rechnen bei. Auf diese Weise wird den Lernenden
      ermöglicht, sich von der zählenden Anzahlermittlung zu lösen und einen
      strukturfokussierenden Blick einzunehmen. Dies gelingt jedoch erst nach
      erfolgreicher Automatisierung der Zahlentripel bestehend aus dem Ganzen
      und den Teilen.
    </p>
    <p>
      Insbesondere durch die Ausbildung der Fähigkeit Zahlen zu zerlegen und die
      Beziehungen zwischen Zahlen und ihren Teilen zu erfassen, werden
      Einsichten in die Beziehung zwischen einzelnen Teilen und dem Ganzen der
      Teile gegeben. Von grundlegender Bedeutung ist dabei die Erkenntnis, dass
      sich durch die Veränderung der einen Teilmenge auch die zweite Teilmenge
      (vorhersagbar) verändert. Um Rechenaufgaben nicht-zählend zu lösen und
      operative Rechenstrategien flexibel einsetzen zu können, müssen Kinder
      spezifische Beziehungen zwischen Zahlen verstehen und bestimmte
      Zahlentripel automatisieren.
    </p>
    <p>
      Gaidoschik (2010b) betont die Bedeutung der Automatisierung eines "Denkens
      in Zusammenhängen“. Dabei geht er von zwei konkreten „Stützpunkten“ aus,
      welche in "Automatisierungsgruppen“ abgesichert werden sollen. Ein
      Stützpunkt und die dazugehörige Automatisierungsgruppe bildet die
      Standardzerlegung „Kraft der Fünf" mit Hilfe der
      <strong>konstant gegebenen 5</strong> und mit der 5
      <strong>als gesuchte Differenz</strong>.
    </p>
    <div class="flex">
      <div class="image">
        <p>5 konstant gegeben</p>
        <AppExpandableImage
          contain
          :src="require('@/assets/nzr/TeilGanzes5Konstant.png')"
          parentId="nzr"
          elementId="nzr_teilganzes5K.png"
        >
        </AppExpandableImage>
      </div>
      <div class="image">
        <p>5 als gesuchte Differenz</p>
        <AppExpandableImage
          contain
          :src="require('@/assets/nzr/TeilGanzes5Diff.png')"
          parentId="nzr"
          elementId="nzr_teilganzes5D.png"
        >
        </AppExpandableImage>
      </div>
    </div>
    <p>
      Daraus kann zum Beispiel die Automatisierungsgruppe der Veränderung um 1
      (mehr bzw. weniger) ausgehend von „Kraft der Fünf" abgeleitet werden.
    </p>
    <div class="flex">
      <div class="image">
        <p>6 konstant gegeben</p>
        <AppExpandableImage
          contain
          :src="require('@/assets/nzr/TeilGanzes6Konstant.png')"
          parentId="nzr"
          elementId="nzr_teilganzes6K.png"
        >
        </AppExpandableImage>
      </div>
      <div class="image">
        <p>4 konstant gegeben</p>
        <AppExpandableImage
          contain
          :src="require('@/assets/nzr/TeilGanzes4Konstant.png')"
          parentId="nzr"
          elementId="nzr_teilganzes4K.png"
        >
        </AppExpandableImage>
      </div>
    </div>
    <p>
      Um den Lernprozess von Schüler:innen unterstützen zu können, sind Sie nun
      aufgefordert das Profitieren von inhaltsreichem Darbieten von Übungen zu
      Zahlentriplen nachzuvollziehen:
    </p>
    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[0].id"
        :workOrder="LBSTPfade[0].workOrder"
        :image="LBSTPfade[0].image"
        :imageSizePercent="LBSTPfade[0].imageSizePercent"
        :mediaDescription="LBSTPfade[0].mediaDescription"
        :audio="LBSTPfade[0].audio"
        :elements="LBSTPfade[0].elements"
        :responses="LBSTPfade[0].responses"
        :pupilName="LBSTPfade[0].pupilName"
      />
    </div>
    <p>
      Der automatisierte Umgang mit den Zahlentripeln stellt häufig eine
      Herausforderung für Schüler:innen mit mathematischen Lernschwierigkeiten
      dar (PIKAS-MI-Team, o.J.). Aus diesem Grund sollte der Fokus nicht auf dem
      unstrukturierten Auswendiglernen liegen, sondern auf der Einsicht in
      Strukturen.
    </p>
    <p>
      Das Teil-Ganzes-Konzept stellt eine essentielle Grundlage für die Ablösung
      vom zählenden Rechnen dar, die jedoch erst in Beziehung mit weiteren
      mathematischen Mustern das nicht-zählende, flexible Rechnen unterstützen
      kann.
    </p>
    <AppLiteraturNZR />
    <AppBottomNavNZR
      back="/nicht-zaehlendes-rechnen/hintergrundwissen/wieso-ist-zaehlendes-rechnen-problematisch"
      next="/nicht-zaehlendes-rechnen/hintergrundwissen/aufbau-flexibler-rechenstrategien"
    />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
import AppLiteraturNZR from "@/common/AppLiteraturNZR";
import AppExpandableImage from "@/common/AppExpandableImage";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";

import Pfade_Auto from "@/components/faledia/seiten/nichtZaehlendesRechnen/LBST/LBST_Pfade_Automatisierung";

export default {
  components: {
    AppBottomNavNZR,
    LernbausteinPfade,
    AppLiteraturNZR,
    AppExpandableImage,
  },
  data: () => ({ LBSTPfade: [Pfade_Auto] }),
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  margin-block-end: 1%;
  justify-content: space-between;
  margin-inline: 2%;
}
.image {
  max-width: 40%;
}
</style>
